import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";
import usePageData from "hooks/usePageData";

const MobileHeader = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const { titleLangId } = usePageData();

  const { active, title = uiTexts[titleLangId], children, className, ...rest } = props;

  return (
    <div className={classNames(Css.mobileHeader, active && Css.active, className)} {...rest}>
      <div className={classNames(Css.container, CommonCss.mobileContainer)}>
        <div className={Css.title}>
          <span>{title}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default React.memo(MobileHeader);
